<template>
  <v-pagination
    v-if="pagination.lastPage > 1"
    v-model="pagination.page"
    :length="pagination.lastPage"
  ></v-pagination>
  <div v-else></div>
</template>

<script>
export default {
  name: "query-page-redirect",
  props: ["pagination"],
  created() {
    if (!this.$route.query.page || !this.$route.query.parent_page) {
      this.updateRouteAndRedirect();
    }
  },
  watch: {
    "$route.query.page"(val) {
      this.fetchItems(val);
    },
    "pagination.page"(val) {
      this.updateRouteAndRedirect(val);
    },
  },
  methods: {
    updateRouteAndRedirect(page) {
      if (this.$route.query.page && page == this.$route.query.page) {
        return;
      }
      let hash = "";
      if (this.$route.query.back_from_id) {
        hash = "#highlight-" + this.$route.query.back_from_id;
      }
      this.$router.replace({
        name: this.$route.name,
        query: this.getQry(page),
        hash: hash,
      });
    },
    getQry(page) {
      let qry = {
        page: 1,
        parent_page: 1,
        parent_parent_page: 1,
      };
      if (this.$route.query.page) {
        qry.page = this.$route.query.page;
      }
      if (this.$route.query.parent_page) {
        qry.parent_page = this.$route.query.parent_page;
      }
      if (this.$route.query.parent_parent_page) {
        qry.parent_parent_page = this.$route.query.parent_parent_page;
      }
      if (page) {
        qry.page = page;
      }
      return qry;
    },
  },
};
</script>
