<template>
  <div>
    <v-card class="mb-4 primary darken-4" v-if="item" dark>
      <v-card-title>
        <v-btn
          icon
          @click="
            $router.push({
              name: 'learn',
              query: { page: $route.query.parent_page },
            })
          "
        >
          <v-icon>mdi-arrow-left</v-icon></v-btn
        >
        <v-spacer></v-spacer>
        <span v-if="$store.getters.getCurrLocale == 'en'">{{
          item.title
        }}</span>
        <span v-else>{{ item.title_hi ? item.title_hi : item.title }}</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-list v-if="items" class="primary darken-2" dark>
        <v-list-item v-if="items && items.length">
          <v-list-item-title>
            {{ $lang("Total") + " " + $lang("Questions") }}
          </v-list-item-title>
          <v-list-item-action>
            {{ pagination.total ? pagination.total : items.length }}
          </v-list-item-action>
        </v-list-item>
        <v-card-actions v-else>
          <v-spacer></v-spacer>
          {{ $lang("No items") }}
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-list>
    </v-card>
    <div class="mb-3" v-for="(qstn, idx) in items" :key="idx">
      <v-card v-if="$store.getters.getCurrLocale == 'en'">
        <v-card-title class="subtitle-1">
          {{ qstn.title }}
          <report-question-dialog
            class="btn-pos"
            :withAnswer="true"
            :question_id="qstn.id"
          />
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <latex-html :html="getAnswer(qstn)" />
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-card-title class="subtitle-1">
          {{ qstn.title_hi ? qstn.title_hi : qstn.title }}
          <report-question-dialog
            :withAnswer="true"
            class="btn-pos"
            :question_id="qstn.id"
          />
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <latex-html :html="getAnswer(qstn)" />
        </v-card-text>
      </v-card>
    </div>
    <v-col cols="12">
      <query-page-redirect :pagination="pagination" />
    </v-col>
    <v-progress-linear
      color="orange accent-4"
      indeterminate
      rounded
      height="6"
      v-if="loading"
    ></v-progress-linear>
  </div>
</template>

<script>
import { axios } from "@/plugins/axios";
import bus from "@/bus";
import LatexHtml from "@/components/LatexHtml";
import QueryPageRedirect from "@/components/QueryPageRedirect";
import ReportQuestionDialog from "@/components/ReportQuestionDialog";
export default {
  name: "tag-questions",
  components: { ReportQuestionDialog, LatexHtml, QueryPageRedirect },
  data() {
    return {
      item: null,
      items: [],
      pagination: {
        lastPage: null,
        page: null,
        perPage: null,
        total: null,
      },
      loading: false,
    };
  },
  computed: {},
  methods: {
    getAnswer(qstn) {
      let lcl = this.$store.getters.getCurrLocale;
      switch (qstn.correct_option) {
        case "1":
          return lcl == "en"
            ? qstn.option_a
            : qstn.option_a_hi
            ? qstn.option_a_hi
            : qstn.option_a;
          break;
        case "2":
          return lcl == "en"
            ? qstn.option_b
            : qstn.option_b_hi
            ? qstn.option_b_hi
            : qstn.option_b;
          break;
        case "3":
          return lcl == "en"
            ? qstn.option_c
            : qstn.option_c_hi
            ? qstn.option_c_hi
            : qstn.option_c;
          break;
        case "4":
          return lcl == "en"
            ? qstn.option_d
            : qstn.option_d_hi
            ? qstn.option_d_hi
            : qstn.option_d;
          break;

        default:
          return null;
          break;
      }
    },
    fetchItems(page = 1) {
      this.loading = true;
      bus.$emit("showWait");
      axios
        .get(
          "prep/tags/public/" +
            this.$route.params.id +
            "/questions?page=" +
            page
        )
        .then((res) => {
          this.items = res.data.data;
          this.pagination = {
            lastPage: res.data.lastPage,
            page: res.data.page,
            perPage: res.data.perPage,
            total: res.data.total,
          };
        })

        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
          bus.$emit("hideWait");
        });
    },
    fetchItem() {
      this.loading = true;
      bus.$emit("showWait");

      axios
        .get("prep/tags/public/" + this.$route.params.id)
        .then((res) => {
          this.item = res.data;
        })
        .then(() => {
          this.fetchItems(this.$route.query.page);
        })
        .catch((err) => {
          console.log(err);
          this.$router.replace({ name: "learn" });
        })
        .finally(() => {
          this.loading = false;
          bus.$emit("hideWait");
        });
    },
  },
  created() {
    if (
      this.$route.query &&
      this.$route.query.page &&
      this.$route.query.page > 0
    ) {
      this.fetchItem();
    }
  },
};
</script>

<style scoped>
.btn-pos {
  position: absolute;
  top: -0.6rem;
  right: 0rem;
}
</style>