<template>
  <span>
    <v-dialog persistent v-model="dialog" fullscreen max-width="1000">
      <template v-slot:activator="{ on, attrs }">
        <v-btn x-small icon v-bind="attrs" v-on="on">
          <v-icon>mdi-alert-circle</v-icon>
        </v-btn>
      </template>

      <v-app-bar app color="deep-purple accent-4" dense dark>
        <v-toolbar-title>{{
          $lang("report") + " " + $lang("question")
        }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn
          text
          outlined
          dark
          small
          class="deep-purple accent-2"
          @click="submitCorrection = !submitCorrection"
        >
          <v-icon v-if="submitCorrection" left>mdi-arrow-left</v-icon>

          {{
            submitCorrection ? $lang("Report Errors") : $lang("Add Corrections")
          }}
          <v-icon v-if="!submitCorrection" right>mdi-arrow-right</v-icon>
        </v-btn>

        <v-btn @click="dialog = false" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <div class="grey darken-4 pb-14 pt-8" style="height: 100vh">
        <v-card class="pb-10" v-if="item && item.id">
          <v-card-text v-if="!submitCorrection">
            <div class="py-4">
              <latex-html v-if="cLang == 'hi'" :html="item.title_hi" />
              <latex-html v-else :html="item.title" />
            </div>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="6" sm="4" md="4">
                <v-checkbox
                  dense
                  v-model="hasCorrection"
                  :label="$lang('incorrect') + ' ' + $lang('question')"
                  value="title"
                />
                <v-checkbox
                  dense
                  v-model="hasCorrection"
                  :label="$lang('incorrect') + ' ' + $lang('optiona')"
                  value="option_a"
                />
                <v-checkbox
                  dense
                  v-model="hasCorrection"
                  :label="$lang('incorrect') + ' ' + $lang('optionc')"
                  value="option_c"
                />

                <v-checkbox
                  v-if="includeAnswer"
                  dense
                  v-model="hasCorrection"
                  :label="$lang('incorrect') + ' ' + $lang('explanation')"
                  value="explanation"
                />
              </v-col>
              <v-col cols="6" sm="4" md="4">
                <v-checkbox
                  dense
                  v-model="hasCorrection"
                  :label="$lang('incorrect') + ' ' + $lang('description')"
                  value="description"
                />
                <v-checkbox
                  dense
                  v-model="hasCorrection"
                  :label="$lang('incorrect') + ' ' + $lang('optionb')"
                  value="option_b"
                />
                <v-checkbox
                  dense
                  v-model="hasCorrection"
                  :label="$lang('incorrect') + ' ' + $lang('optiond')"
                  value="option_d"
                />
                <v-checkbox
                  v-if="includeAnswer"
                  dense
                  v-model="hasCorrection"
                  :label="$lang('incorrect') + ' ' + $lang('answer')"
                  value="correct_option"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text v-if="submitCorrection">
            <v-row>
              <v-col cols="12" md="6">
                <v-tabs color="orange" grow>
                  <v-tab v-if="!item.language || item.language == 'en'">
                    English
                  </v-tab>
                  <v-tab v-if="!item.language || item.language == 'hi'">
                    हिन्दी
                  </v-tab>

                  <v-tab-item v-if="!item.language || item.language == 'en'">
                    <v-row>
                      <v-col cols="12">
                        <vue-tip
                          :variant="variant"
                          color="orange"
                          dark
                          :initial="item.title"
                          :errors="errors && errors.title ? errors.title : []"
                          :placeholder="$lang('TITLE')"
                          @changed="
                            (val) => {
                              item.title = val;
                            }
                          "
                        />
                        <vue-tip
                          v-if="
                            item.description && item.description.stripTags()
                          "
                          :variant="variant"
                          color="primary"
                          dark
                          :initial="item.description"
                          :errors="
                            errors && errors.description
                              ? errors.description
                              : []
                          "
                          :placeholder="$lang('DESCRIPTION')"
                          @changed="
                            (val) => {
                              item.description = val;
                            }
                          "
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="6">
                        <vue-tip
                          :variant="variant"
                          :initial="item.option_a"
                          :errors="
                            errors && errors.option_a ? errors.option_a : []
                          "
                          :placeholder="$lang('OPTIONA')"
                          @changed="
                            (val) => {
                              item.option_a = val;
                            }
                          "
                        />
                      </v-col>
                      <v-col cols="6">
                        <vue-tip
                          :variant="variant"
                          :initial="item.option_b"
                          :errors="
                            errors && errors.option_b ? errors.option_b : []
                          "
                          :placeholder="$lang('OPTIONB')"
                          @changed="
                            (val) => {
                              item.option_b = val;
                            }
                          "
                        />
                      </v-col>
                      <v-col cols="6">
                        <vue-tip
                          :variant="variant"
                          :initial="item.option_c"
                          :errors="
                            errors && errors.option_c ? errors.option_c : []
                          "
                          :placeholder="$lang('OPTIONC')"
                          @changed="
                            (val) => {
                              item.option_c = val;
                            }
                          "
                        />
                      </v-col>
                      <v-col cols="6">
                        <vue-tip
                          :variant="variant"
                          :initial="item.option_d"
                          :errors="
                            errors && errors.option_d ? errors.option_d : []
                          "
                          :placeholder="$lang('OPTIOND')"
                          @changed="
                            (val) => {
                              item.option_d = val;
                            }
                          "
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" v-if="includeAnswer">
                        <vue-tip
                          :variant="variant"
                          color="green"
                          :initial="item.explanation"
                          :errors="
                            errors && errors.explanation
                              ? errors.explanation
                              : []
                          "
                          :placeholder="$lang('explanation')"
                          @changed="
                            (val) => {
                              item.explanation = val;
                            }
                          "
                        />
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item v-if="!item.language || item.language == 'hi'">
                    <v-row>
                      <v-col cols="12">
                        <vue-tip
                          :variant="variant"
                          color="orange"
                          dark
                          :initial="item.title_hi"
                          :errors="
                            errors && errors.title_hi ? errors.title_hi : []
                          "
                          :placeholder="$lang('TITLE')"
                          @changed="
                            (val) => {
                              item.title_hi = val;
                            }
                          "
                        />
                        <vue-tip
                          v-if="
                            item.description_hi &&
                            item.description_hi.stripTags()
                          "
                          :variant="variant"
                          color="primary"
                          dark
                          :initial="item.description_hi"
                          :errors="
                            errors && errors.description_hi
                              ? errors.description_hi
                              : []
                          "
                          :placeholder="$lang('DESCRIPTION')"
                          @changed="
                            (val) => {
                              item.description_hi = val;
                            }
                          "
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="6">
                        <vue-tip
                          :variant="variant"
                          :initial="item.option_a_hi"
                          :errors="
                            errors && errors.option_a_hi
                              ? errors.option_a_hi
                              : []
                          "
                          :placeholder="$lang('OPTIONA')"
                          @changed="
                            (val) => {
                              item.option_a_hi = val;
                            }
                          "
                        />
                      </v-col>
                      <v-col cols="6">
                        <vue-tip
                          :variant="variant"
                          :initial="item.option_b_hi"
                          :errors="
                            errors && errors.option_b_hi
                              ? errors.option_b_hi
                              : []
                          "
                          :placeholder="$lang('OPTIONB')"
                          @changed="
                            (val) => {
                              item.option_b_hi = val;
                            }
                          "
                        />
                      </v-col>
                      <v-col cols="6">
                        <vue-tip
                          :variant="variant"
                          :initial="item.option_c_hi"
                          :errors="
                            errors && errors.option_c_hi
                              ? errors.option_c_hi
                              : []
                          "
                          :placeholder="$lang('OPTIONC')"
                          @changed="
                            (val) => {
                              item.option_c_hi = val;
                            }
                          "
                        />
                      </v-col>
                      <v-col cols="6">
                        <vue-tip
                          :variant="variant"
                          :initial="item.option_d_hi"
                          :errors="
                            errors && errors.option_d_hi
                              ? errors.option_d_hi
                              : []
                          "
                          :placeholder="$lang('OPTIOND')"
                          @changed="
                            (val) => {
                              item.option_d_hi = val;
                            }
                          "
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" v-if="includeAnswer">
                        <vue-tip
                          :variant="variant"
                          color="green"
                          :initial="item.explanation_hi"
                          :errors="
                            errors && errors.explanation_hi
                              ? errors.explanation_hi
                              : []
                          "
                          :placeholder="$lang('explanation')"
                          @changed="
                            (val) => {
                              item.explanation_hi = val;
                            }
                          "
                        />
                      </v-col>
                    </v-row>
                  </v-tab-item>
                </v-tabs>
              </v-col>

              <v-col cols="12" md="6">
                <v-select
                  v-model="item.correct_option"
                  :items="options"
                  item-text="value"
                  item-value="key"
                  :label="$lang('CORRECT-OPTION')"
                  :error-messages="
                    errors && errors.correct_option ? errors.correct_option : []
                  "
                ></v-select>
              </v-col>
              <!-- right side end -->
            </v-row>
          </v-card-text>

          <v-divider></v-divider>
        </v-card>
      </div>
      <v-bottom-navigation horizontal grow fixed v-model="bottomNav">
        <v-btn
          color="green darken-4"
          dark
          :loading="loading"
          @click="submitForReview()"
          block
        >
          {{
            submitCorrection
              ? $lang("Submit Corrections").toUpperCase()
              : $lang("Report Errors").toUpperCase()
          }}
          <v-icon>mdi-send</v-icon>
        </v-btn>
      </v-bottom-navigation>
    </v-dialog>
  </span>
</template>
<script>
import { axios } from "@/plugins/axios";
import bus from "@/bus";
import { getRandomColorLuminance } from "@/helpers/utils";
import VueTip from "@/components/VueTip.vue";
// import RadioHtml from "@/app/exams/modules/RadioHtml.vue";
import LatexHtml from "@/components/LatexHtml";

export default {
  components: {
    VueTip,
    LatexHtml,
    //  RadioHtml
  },
  name: "report-question-dialog",
  props: ["question_id", "withAnswer"],
  data() {
    return {
      includeAnswer: false,
      bottomNav: "reportReview",
      variant: "none",
      item: {},
      dialog: false,
      loading: false,
      submitCorrection: false,
      hasCorrection: [],
      options: [
        { key: "1", value: this.$lang("OPTIONA") },
        { key: "2", value: this.$lang("OPTIONB") },
        { key: "3", value: this.$lang("OPTIONC") },
        { key: "4", value: this.$lang("OPTIOND") },
      ],
    };
  },
  computed: {
    errors: {
      get() {
        return this.$store.getters.getFormErrors;
      },
      set(_) {},
    },
    cLang() {
      return this.$store.getters["app/exams/selectedTestLanguage"];
      // return this.$store.getters.getCurrLocale;
    },
  },
  watch: {
    withAnswer(val) {
      this.includeAnswer = val;
    },
    question_id(id) {
      if (this.dialog) {
        this.fetchItem(id);
      }
    },
    dialog(val) {
      if (val) {
        bus.$emit("hideBottomNav");
        bus.$emit("hideAppBar");
        bus.$emit("hideAppDrawer");

        this.fetchItem(this.question_id);
        this.includeAnswer = this.withAnswer;
      } else {
        bus.$emit("showBottomNav");
        bus.$emit("showAppBar");

        this.includeAnswer = false;
        this.item = {};
        this.submitCorrection = false;
        this.hasCorrection = [];
      }
    },
  },
  methods: {
    submitForReview() {
      this.loading = false;
      bus.$emit("showWait");
      return axios
        .post("prep/report/question/", {
          correctionData: this.item,
          hasCorrection: this.hasCorrection,
        })
        .then((res) => {
          this.item = res.data;
        })
        .finally(() => {
          bus.$emit("hideWait");
          this.loading = false;
          this.dialog = false;
        });
    },
    fetchItem(id) {
      this.loading = false;
      bus.$emit("showWait");
      return axios
        .get("prep/questions/public/" + id)
        .then((res) => {
          this.item = res.data;
        })
        .finally(() => {
          bus.$emit("hideWait");
          this.loading = false;
        });
    },
    randomColor() {
      return getRandomColorLuminance(-0.5);
    },
  },
  created() {
    this.includeAnswer = this.withAnswer;
    if (this.dialog) {
      this.fetchItem(this.question_id);
    }
  },

  destroyed() {
    bus.$emit("showBottomNav");
    bus.$emit("showAppBar");
  },
};
</script>
<style scoped>
.report-question-btn {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
</style>